//  Variables

$app-content-sidebar-width:       280px;
$app-content-sidebar-sm-width:    250px;
$app-content-sidebar-lg-width:    380px;
$app-content-sidebar-xl-width:    440px;
$app-content-sidebar-xxl-width:   520px;
$btn-pane-height:                 80px;

$sidebar-width-collapsed:   60px;

$sidebar-shadow-light:            0px 0px 28px 0px rgba($primary, 0.08);
