// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.clear-both {
  display: block;
  clear: both;
  content: "";
}

.scroll-bar {
  overflow: auto;
}

.border-right {
  border-right: #DFDFDF solid 1px !important;
  padding-right: 17px;
  height: 33px;
}

.nowrap {
  white-space: nowrap;
}

.pt-5px {
  padding-top: 5px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt--4 {
  margin-top: -4px !important;
}

.b-1 {
  border: 1px solid !important;
}

.w-80px {
  width: 80px !important;
}

.mr-100px {
  margin-right: 100px;
}

.w-85 {
  width: 85% !important;
}

.w-15 {
  width: 15% !important;
}

.background-blue {
  background: rgba(65, 145, 255, 0.15) !important;
}

.background-yellow {
  background: #fdeab2 !important;
}

.p-10-0 {
  padding: 10px 0px;
}
